.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.FormInput-input-960 {
  width: 400px;
}

.warnings {
}

.dropzone-container {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #111111;
  border-style: dashed;
  background-color: #dddddd;
  color: #111111;
  outline: none;
  transition: border 0.24s ease-in-out;
}

a.subsidy-errors-link {
  color: white;
  background-color: rgb(17, 82, 147);
  margin: auto;
}
.custom-tab {
  padding: 12px 16px !important;
  text-decoration: none;
  color: #3f51b5; /* Couleur de texte de Material-UI */
  border-bottom: 2px solid transparent; /* Aucune bordure par défaut */
  transition: border-color 0.3s, color 0.3s; /* Transition douce */
  display: inline-block;
  margin-right: 16px; /* Espacement entre les onglets */
}

.custom-tab:hover {
  border-bottom: 2px solid #3f51b5; /* Bordure lors du survol */
}

.custom-tab.active {
  border-bottom: 2px solid #3f51b5; /* Bordure lorsque l'onglet est actif */
  color: #1976d2; /* Couleur active */
}
