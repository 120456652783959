.form-group {
  position: relative;
  outline: none;
}

.form-group ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.autocompletion-wrapper {
  position: absolute;
  width: 100%;
  top: 110%;
  left: 0;
  z-index: 11;
  background-color: white;
  border: solid 1px #7bbedf;
  border-radius: 4px;
  overflow: hidden;
  z-index: 100;
}
.autocompletion-wrapper li {
  position: relative;
}
.autocompletion-wrapper li .autocompletion-item {
  height: 3em;
  display: flex;
  align-items: center;
  padding: 0;
  margin: auto;
  width: 95%;
  border-bottom: 1px solid #c1c1c1;
  font-size: 0.9rem;
  font-weight: 400;
}
.autocompletion-wrapper li:last-of-type .autocompletion-item {
  border: none;
}
.autocompletion-wrapper li .autocompletion-elem {
  position: relative;
  transition: all 0.25s ease;
  cursor: pointer;
  display: block;
}
.autocompletion-wrapper li .autocompletion-elem .icon {
  display: inline-block;
  vertical-align: middle;
  max-width: 30px;
  padding: 0 0 0 10px;
  transition: all 0.25s ease;
}
.autocompletion-wrapper li.no-result {
  background-color: #01dcbe;
}
.autocompletion-wrapper li.loading {
  padding: 5px;
}
.autocompletion-wrapper li.loading :before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  left: 50%;
  top: 50%;
  background-color: #01dcbe;
  border-radius: 50%;
  transform: scale(0) translate3d(-50%, -50%, 0);
  transform-origin: left 50%;
  animation: 0.5s loading-result ease infinite;
}
.autocompletion-wrapper li span {
  transition: all 0.25s ease;
  transform: translate3d(0, 0, 0);
  display: inline-block;
  vertical-align: middle;
  width: calc(70%);
  padding: 10px;
  font-weight: 300;
  font-size: 1.1em;
}
.autocompletion-wrapper li:hover:not(.no-result) {
  background-color: #f0f0f0;
}
.autocompletion-wrapper li:hover:not(.no-result) .autocompletion-item {
  display: flex;
}
.autocompletion-wrapper li:hover:not(.no-result) span,
.autocompletion-wrapper li:hover:not(.no-result) .icon {
  display: inline-block;
  transform: translate3d(5px, 0, 0);
}
@keyframes loading-result {
  0% {
    opacity: 0;
    transform: scale(0) translate3d(-50%, -50%, 0);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(-50%, -50%, 0);
  }
}
